@import "./variables.scss";

.react-datepicker-wrapper {
  width: 80%;
}

.react-datepicker__input-container > input {
  background-color: white;
  color: var(--chakra-colors-gray-500);
  font-size: 13px;
}

.react-datepicker__month-container {
  font-family: "Montserrat Regular";
  background-color: var(--chakra-colors-grey6);
}

.react-datepicker__header {
  color: var(--chakra-colors-gray-600);
  background-color: var(--chakra-colors-grey6);
  border: none;
  font-size: 15px;
  line-height: 20px;
}

.react-datepicker__day {
  color: var(--chakra-colors-black);

  &:hover {
    border-radius: 50px;
    background-color: var(--chakra-colors-yellow15);
  }
}

.react-datepicker__day--today {
  border: 1px solid var(--chakra-colors-gray-400);
  border-radius: 50px;
  background-color: transparent;
  width: 30px;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-range {
  color: var(--chakra-colors-black);
  background-color: var(--chakra-colors-yellow);
  border-radius: 50px;

  &:hover {
    background-color: var(--chakra-colors-yellow15);
    border-radius: 50px;
  }
}

.react-datepicker__close-icon::after {
  color: var(--chakra-colors-black);
  background-color: var(--chakra-colors-yellow);
}

.react-datepicker__header__dropdown,
.react-datepicker__header__dropdown--scroll {
  display: flex;
  justify-content: space-around;
  margin: 4px 0px;
}

.react-datepicker__month-dropdown-container,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__year-dropdown-container,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__current-month,
.react-datepicker__current-month--hasYearDropdown,
.react-datepicker__current-month--hasMonthDropdown {
  color: var(--chakra-colors-gray-600);
}

.react-datepicker__year-option,
.react-datepicker__month-option {
  font-size: 18px;
  line-height: 24px;

  &:hover {
    background-color: var(--chakra-colors-yellow15);
  }
}

.react-datepicker__year-option--selected_year,
.react-datepicker__month-option--selected_month {
  background-color: var(--chakra-colors-yellow);
}

.react-datepicker__month {
  font-size: 18px;
  line-height: 24px;
}

.react-datepicker__day-name {
  font-size: 20px;
  line-height: 28px;
  color: var(--chakra-colors-gray-500);
}
